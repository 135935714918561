import React from 'react';
import orderList from '../../margemonitor/components/orders/dimensions-HOE';
import ritList from '../../margemonitor/components/ritten/dimensions-HOE';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-HOE';
const Dashboard = React.lazy(() => import('./../../views/Maatwerk/HOE/Dashboard'));
const Rembours = React.lazy(() => import('./../../views/Maatwerk/HOE/Rembours'));
const Schadedetails = React.lazy(() => import('./../../views/Maatwerk/HOE/Schadedetails'));


const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'hoekstrasneek.eu.qlikcloud.com',  
    secure: true,
    prefix: '',
    theme: 'hoekstra',
    webIntegrationId: 'BQVuufQxyMPbPUiHOuNt0QdvJMZ6TXJu',  
    //appId:'40350571-4a6d-4a02-bc77-09d249deb087' //Prod
    appId:'89cc966b-db06-410a-a733-62c3832c27b1'//Dev
  },
    // config: {
    // host: 'transportcostconsult.eu.qlikcloud.com',  
    // secure: true,
    // prefix: '',
    // theme: 'hoekstra',
    // webIntegrationId: 'eZkqPqxa2wU4sKv0EV4DJbHDgICZSkPV',  
    // appId:'4c499c23-e961-4a97-ae5f-5396096f4073'
    //},
  connections: {
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            //autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expanded: false,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expanded: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Period)]'],
            placeholder: 'Periode',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            expanded: true, 
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Week)]'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },  
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Date)]'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Debtor.Name)'],
            placeholder: 'Klantnaam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'DebiteurNummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Status'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Invoiced)]'],
            placeholder: 'Order gefactureerd',
            showStateInDropdown: true,
          },
        },
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        title: true,
        name: 'Maatwerk',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: 'Dashboard',
        url: '/hoe/dashboard',
        icon: 'fa fa-tachometer-alt',
      },
      {
        name: 'Rembours',
        url: '/hoe/rembours',
        icon: 'fa fa-euro-sign',
      },
      {
        name: 'Schadedetails',
        url: '/hoe/schadedetails',
        icon: 'fa fa-car-crash',
      },
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: 'Klantrapport', 
        url: 'https://klantrapport.hoekstra.margemonitor.nl',
        icon: 'fa fa-file',
        attributes: { target: '_blank', active: false },
      },
      {
        name: 'Managementrapport', 
        url: 'https://managementrapport.hoekstra.margemonitor.nl',
        icon: 'fa fa-file',
        attributes: { target: '_blank', active: false },
      },
      {
        name: 'WMS Dashboard', 
        url: 'https://hoekstrasneek.eu.qlikcloud.com/sense/app/2fb3c0fa-52a0-40a1-906e-3c6b112a0fd2',
        icon: 'fa fa-warehouse',
        attributes: { target: '_blank', active: false },
      },
    ],
  },
  routes: [
    { path: '/hoe/dashboard', name: 'HOE / Dashboard', component: Dashboard },
    { path: '/hoe/rembours', name: 'HOE / Rembours', component: Rembours },
    { path: '/hoe/schadedetails', name: 'HOE / Schadedetails', component: Schadedetails },
  ],
  fieldSettings:{
    period: 'vD_Period'
  },
  selectionBar: 'Capability',
  rollingPeriod: false,
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};

export default options;
